import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import PrivacyPolicy from "./components/PrivacyPolicy";
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <SpeedInsights />
      <Routes>
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<App />} />
      </Routes>
      <Analytics />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
