import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Header from "./components/Header";
import Intro from "./components/Intro";
import About from "./components/About";
import Advantages from "./components/Advantages";
import Services from "./components/Services";
import Technologies from "./components/Technologies";
import Footer from "./components/Footer";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";

import IconButton from "@mui/material/IconButton";
import "./App.css";

function App() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [inquiry, setInquiry] = useState("");
  const emailAddress = "sales@codegator.co";

  const sendWhatsappMessage = () => {
    window.open(
      "https://api.whatsapp.com/send?phone=972547713586&text=Hello%2C%20I%20would%20like%20to%20get%20in%20touch%20with%20you.",
      "_blank"
    );
  };
  const handleSendClick = async (event) => {
    event.preventDefault();

    // Assuming `name`, `email`, `phone`, `inquiry` are either state variables or extracted from the form here
    console.log("Form Data:", { name, email, phone, inquiry });

    try {
      const userData = { name, email, phone, inquiry };
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/submitUserData`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      if (response.ok) {
        console.log("Data sent successfully!");
        const result = await response.text();
        console.log(result);
        toast("Thanks, we will contact you soon!", { position: "top-center" });
      } else {
        console.error("Failed to send data", response.status);
        toast("There was a problem with your submission. Please try again.", {
          position: "top-center",
        });
      }
    } catch (error) {
      console.error("Error sending data:", error);
      toast("An error occurred. Please try again later.", {
        position: "top-center",
      });
    }

    // Assuming you're using this to close a modal or popup
    document.getElementById("Popup").checked = false;
  };

  return (
    <>
      <ToastContainer />
      <div id="header-space" data-header-mobile-fixed="1"></div>
      <Header />
      <div id="mobile-menu" data-mobile-fixed="1">
        <div className="container">
          <ul>
            <li>
              <a href="#">No menu assigned!</a>
            </li>
          </ul>
        </div>
      </div>
      <div
        id="ajax-loading-screen"
        data-disable-fade-on-click="0"
        data-effect="center_mask_reveal"
        data-method="standard"
      >
        {" "}
        <span className="mask-top"></span> <span className="mask-right"></span>{" "}
        <span className="mask-bottom"></span>{" "}
        <span className="mask-left"></span>{" "}
      </div>
      <div id="ajax-content-wrap">
        <div className="container-wrap">
          <div className=" main-content">
            <div className="row">
              <div
                id="nectar_fullscreen_rows"
                data-animation="parallax"
                data-row-bg-animation="ken_burns"
                data-animation-speed="medium"
                data-content-overflow="hidden"
                data-mobile-disable="on"
                data-dot-navigation="tooltip_alt"
                data-footer="last_row"
                data-anchors="off"
              >
                {/*Intro*/}
                <Intro></Intro>
                {/*About*/}
                <About></About>
                {/* <!--Advantges-->*/}
                <Advantages></Advantages>
                {/* <!--Services-->*/}
                <Services></Services>
                {/* <!--Team-->*/}

                {/* <!--Technologies-->*/}
                <Technologies></Technologies>
                {/* <!--Agenda-->*/}
                {/* <Agenda></Agenda> */}
                {/* <!--Footer-->*/}
                <Footer></Footer>
              </div>
            </div>
          </div>
        </div>
        <div
          id="footer-outer"
          data-midnight="light"
          data-full-width="false"
          data-using-widget-area="false"
        >
          {" "}
        </div>

        <div
          id="slide-out-widget-area-bg"
          className="slide-out-from-right-hover dark"
        ></div>
        <div
          id="slide-out-widget-area"
          className="slide-out-from-right-hover"
          data-back-txt="Back"
        >
          <div className="inner">
            <a className="slide_out_area_close" href="#">
              <span className="icon-salient-x icon-default-style"></span>
            </a>
            <div className="off-canvas-menu-container mobile-only">
              <ul className="menu">
                <li className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-2 current_page_item menu-item-107">
                  <a href="#About">About</a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-2 current_page_item menu-item-107">
                  <a href="#OurAdvantages">Advantages</a>
                </li>
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-106">
                  <a href="#OurServices">Services</a>
                </li>
                {/* <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-106">
                  <a href="#Portfolio">Projects</a>
                </li> */}
                {/* <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-106">
                  <a href="#Team">Team</a>
                </li> */}
                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-106">
                  <a href="#Technologies">Technologies</a>
                </li>
                {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-108">
                  <a href="#Agenda">Agenda</a>
                </li> */}
              </ul>
              <label className="m-contact" htmlFor="Popup">
                Contact
              </label>
            </div>
          </div>
          <div className="bottom-meta-wrap">
            <ul className="off-canvas-social-links mobile-only">
              <li>
                <a target="_blank" href="#">
                  <i className="fa fa-twitter"></i>{" "}
                </a>
              </li>
              <li>
                <a target="_blank" href="#">
                  <i className="fa fa-facebook"></i>{" "}
                </a>
              </li>
              <li>
                <a target="_blank" href="#">
                  <i className="fa fa-youtube-play"></i>{" "}
                </a>
              </li>
            </ul>
          </div>
          {/* <!--bottom-meta-wrap-->*/}
        </div>
      </div>
      <a id="to-top" className="mobile-enabled">
        <i className="fa fa-angle-up"></i>
      </a>
      <input type="checkbox" className="hidden" autoComplete="off" id="Popup" />
      <div className="contact-popup">
        <div className="container">
          <label htmlFor="Popup" className="icon-exit contact-exit"></label>
          <div className="form">
            <span className="title">Interested? fill in your details</span>
            <form>
              <div className="input">
                <label htmlFor="Name">
                  Your Name <span className="required">*</span>
                </label>
                <input
                  name="Name"
                  type="text"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="input">
                <label htmlFor="Email">
                  Email Address<span className="required">*</span>
                </label>
                <input
                  required={true}
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input phone">
                <label htmlFor="Phone">
                  Your Phone <span className="required">*</span>
                </label>
                <input
                  name="Phone"
                  type="text"
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="input">
                <label htmlFor="Inquiry">Your inquiry</label>
                <textarea
                  name="Inquiry"
                  value={inquiry}
                  onChange={(e) => setInquiry(e.target.value)}
                ></textarea>
              </div>
              <button
                type="submit"
                onClick={handleSendClick}
                disabled={!email.trim()}
              >
                Send
              </button>
              <div>
                <p
                  href="mailto:sales@codegator.co"
                  style={{ marginTop: "10px" }}
                >
                  sales@codegator.co
                </p>
                <div
                  style={{
                    marginTop: "-38px",
                  }}
                >
                  <IconButton
                    onClick={sendWhatsappMessage}
                    aria-label="Send whatsapp message"
                  >
                    <WhatsAppIcon />
                  </IconButton>
                  <a
                    href={`mailto:${emailAddress}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IconButton aria-label="send email">
                      <EmailIcon />
                    </IconButton>
                  </a>
                </div>
              </div>
            </form>
          </div>
          <div className="thank-u hidden">
            <span className="text">
              Thank you,
              <br />
              We will get back to you ASAP!
            </span>
          </div>
        </div>
        <label htmlFor="Popup" className="overlay contact-exit"></label>
      </div>
    </>
  );
}

export default App;
