import React from "react";

function Technologies(props) {
  return (
    <div
      id="Technologies"
      data-fullscreen-anchor-id="Technologies-s"
      data-midnight="dark"
      data-bg-mobile-hidden=""
      className="our-skills wpb_row vc_row-fluid vc_row full-width-section standard_section"
      style={{}}
    >
      <div className="full-page-inner-wrap-outer">
        <div
          className="full-page-inner-wrap"
          data-name="Proven Technologies"
          data-content-pos="middle"
        >
          <div className="full-page-inner">
            <div className="row-bg-wrap">
              <div
                className="row-bg  using-bg-color  "
                //style="background-color: #f7f7f7; "
                style={{ backgroundColor: "#f7f7f7" }}
                data-color_overlay=""
                data-color_overlay_2=""
                data-gradient_direction=""
                data-overlay_strength="0.95"
                data-enable_gradient="false"
              ></div>
            </div>
            <div className="container">
              <div className="col span_12 dark left">
                <div
                  className="vc_col-sm-12 wpb_column column_container vc_column_container col no-extra-padding"
                  data-bg-cover=""
                  data-padding-pos="all"
                  data-has-bg-color="false"
                  data-bg-color=""
                  data-bg-opacity="1"
                  data-hover-bg=""
                  data-hover-bg-opacity="1"
                  data-animation=""
                  data-delay="0"
                >
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      <div
                        id="fws_58a74787dd935"
                        data-midnight=""
                        data-bg-mobile-hidden=""
                        className="wpb_row vc_row-fluid vc_row standard_section"
                        style={{
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <div className="row-bg-wrap">
                          <div className="row-bg" style={{}}></div>
                        </div>
                        <div className="col span_12  left">
                          <div
                            className="vc_col-sm-12 wpb_column column_container vc_column_container col centered-text has-animation no-extra-padding"
                            data-bg-cover=""
                            data-padding-pos="all"
                            data-has-bg-color="false"
                            data-bg-color=""
                            data-bg-opacity="1"
                            data-hover-bg=""
                            data-hover-bg-opacity="1"
                            data-animation="fade-in"
                            data-delay="0"
                          >
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div className="wpb_text_column wpb_content_element  vc_custom_1469641915676">
                                  <div className="wpb_wrapper">
                                    <h2>Proven Technologies</h2>
                                  </div>
                                </div>
                                <div className="divider-wrap">
                                  <div
                                    style={{
                                      marginTop: "15px",
                                      width: "120px",
                                      height: "3px",
                                      marginBottom: "15px",
                                    }}
                                    data-width="120"
                                    data-animate="yes"
                                    data-animation-delay="100"
                                    data-color="extra-color-gradient-1"
                                    className="divider-small-border"
                                  ></div>
                                </div>
                                <div
                                  className="wpb_text_column wpb_content_element vc_custom_1469652796078"
                                  style={{
                                    maxWidth: "450px",
                                    display: "inline-block",
                                  }}
                                >
                                  <div className="wpb_wrapper">
                                    <p className="head-p">
                                      <span style={{ color: "#999999" }}>
                                        Taking you to the next level of digital
                                        presence with our updated proven
                                        technologies knowledge.
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="fws_58a74787de19f"
                        data-midnight=""
                        data-bg-mobile-hidden=""
                        className="wpb_row vc_row-fluid vc_row standard_section"
                        style={{
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <div className="tech">
                          <div
                            className="col has-animation"
                            data-animation="grow-in"
                            data-delay="0"
                          >
                            <div>
                              <h5>React</h5>
                              <i className="icon-custom"></i>
                              <span>Next.js</span>
                              <span>React App (CRA)</span>
                              <span>Vite</span>
                            </div>
                          </div>
                          <div
                            className="col has-animation"
                            data-animation="grow-in"
                            data-delay="150"
                          >
                            <div>
                              <h5>C# Backend</h5>
                              <i className="icon-design"></i>
                              <span>MVC</span>
                              <span>Webapi</span>
                              <span>Core</span>
                            </div>
                          </div>
                          <div
                            className="col has-animation"
                            data-animation="grow-in"
                            data-delay="300"
                          >
                            <div>
                              <h5>JS Backend</h5>
                              <i className="icon-custom"></i>
                              <span>Express</span>
                              <span>Node JS</span>
                              <span>NestJs</span>
                            </div>
                          </div>
                          <div
                            className="col has-animation"
                            data-animation="grow-in"
                            data-delay="450"
                          >
                            <div>
                              <h5>Database</h5>
                              <i className="icon-design"></i>
                              <span>MySQL</span>
                              <span>Postgress</span>
                              <span>MongoDB</span>
                              <span>Redis</span>
                            </div>
                          </div>
                          <div
                            className="col has-animation"
                            data-animation="grow-in"
                            data-delay="600"
                          >
                            <div>
                              <h5>DevOps</h5>
                              <i className="icon-custom"></i>
                              <span>AWS</span>
                              <span>Azure</span>
                              <span>FireBase</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Technologies;
