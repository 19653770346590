import React from "react";

function Services(props) {
  return (
    <div
      id="OurServices"
      data-fullscreen-anchor-id="OurServices-s"
      data-midnight="dark"
      data-bg-mobile-hidden=""
      className="wpb_row vc_row-fluid vc_row full-width-section standard_section"
      style={{}}
    >
      <div className="full-page-inner-wrap-outer">
        <div
          className="full-page-inner-wrap"
          data-name="Our Services"
          data-content-pos="middle"
        >
          <div className="full-page-inner">
            <div className="row-bg-wrap">
              <div
                className="row-bg  using-bg-color"
                //   style="background-color: #ffffff; "
                style={{ backgroundColor: "#ffffff" }}
                data-color_overlay=""
                data-color_overlay_2=""
                data-gradient_direction=""
                data-overlay_strength="0.3"
                data-enable_gradient="false"
              ></div>
            </div>
            <div className="container">
              <div className="col span_12 dark left f-mobile-margin">
                <div
                  className="vc_col-sm-12 wpb_column column_container vc_column_container col centered-text no-extra-padding"
                  data-bg-cover=""
                  data-padding-pos="all"
                  data-has-bg-color="false"
                  data-bg-color=""
                  data-bg-opacity="1"
                  data-hover-bg=""
                  data-hover-bg-opacity="1"
                  data-animation=""
                  data-delay="0"
                >
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper f-padding">
                      <div
                        id="fws_58a74787d517"
                        data-midnight=""
                        data-bg-mobile-hidden=""
                        className="f-margin wpb_row vc_row-fluid vc_row standard_section"
                        //   style="padding-top: 0px; padding-bottom: 0px; "
                        style={{
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <div className="row-bg-wrap">
                          <div className="row-bg" style={{}}></div>
                        </div>
                        <div className="col span_12  left">
                          <div
                            className="vc_col-sm-12 wpb_column column_container vc_column_container col has-animation no-extra-padding"
                            data-bg-cover=""
                            data-padding-pos="all"
                            data-has-bg-color="false"
                            data-bg-color=""
                            data-bg-opacity="1"
                            data-hover-bg=""
                            data-hover-bg-opacity="1"
                            data-animation="fade-in"
                            data-delay="0"
                          >
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div className="wpb_text_column wpb_content_element  vc_custom_1469639641867">
                                  <div className="wpb_wrapper">
                                    <h2>Our Services</h2>
                                  </div>
                                </div>
                                <div className="divider-wrap">
                                  <div
                                    // style="margin-top: 15px; width: 120px; height: 3px; margin-bottom: 15px;"
                                    style={{
                                      marginTop: "15px",
                                      width: "120px",
                                      height: "3px",
                                      marginBottom: "15px",
                                    }}
                                    data-width="120"
                                    data-animate="yes"
                                    data-animation-delay="100"
                                    data-color="extra-color-gradient-1"
                                    className="divider-small-border"
                                  ></div>
                                </div>
                                <div
                                  className="wpb_text_column wpb_content_element f-width"
                                  //   style=" max-width: 450px; display: inline-block;"
                                  style={{
                                    maxWidth: "450px",
                                    display: "inline-block",
                                  }}
                                >
                                  <div className="wpb_wrapper">
                                    <p>
                                      {" "}
                                      <span style={{ color: "#999999" }}>
                                        We help entrepreneurs take the first
                                        step and launch amazing projects.
                                      </span>{" "}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="services" data-column-padding="15px">
                        <div
                          className="carousel-item wpb_column has-animation"
                          data-animation="fade-in-from-bottom"
                          data-delay="0"
                        >
                          <div
                            className="nectar-flip-box"
                            data-min-height="300"
                            data-flip-direction="horizontal-to-left"
                            data-h_text_align="center"
                            data-v_text_align="center"
                          >
                            <div
                              className="flip-box-front"
                              data-bg-overlay="true"
                              data-text-color="light"
                              style={{
                                backgroundImage: 'url("images/website.jpg")',
                                backgroundColor: "#0a0a0a",
                                minHeight: "300px",
                              }}
                              //   style="background-image: url('images/websites.jpg'); background-color: #0a0a0a; min-height: 300px;"
                            >
                              <div className="inner">
                                <i
                                  className="icon-default-style icon-websites"
                                  data-color="extra-color-gradient-1"
                                  //  style="font-size: 47px!important; line-height: 47px!important;"
                                  style={{
                                    fontSize: "47px!important",
                                    lineHeight: "47px!important",
                                  }}
                                ></i>
                                <h3>Web Applications</h3>
                                <p>
                                  Full development solution for all kind of
                                  websites.
                                </p>
                              </div>
                            </div>
                            <div
                              className="flip-box-back"
                              data-bg-overlay="true"
                              data-text-color="light"
                              // style="background-image: url('images/websites.jpg'); background-color: #0a0a0a; background-image: url('images/websites.jpg'); min-height: 300px;"
                              style={{
                                backgroundImage: 'url("images/website.jpg")',
                                backgroundColor: "#0a0a0a",
                                minHeight: "300px",
                              }}
                            >
                              <div className="inner">
                                <p>
                                  {" "}
                                  Building efficient and functional web-based
                                  applications and robust back-end
                                  infrastructure.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="carousel-item wpb_column has-animation"
                          data-animation="fade-in-from-bottom"
                          data-delay="200"
                        >
                          <div
                            className="nectar-flip-box"
                            data-min-height="300"
                            data-flip-direction="horizontal-to-left"
                            data-h_text_align="center"
                            data-v_text_align="center"
                          >
                            <div
                              className="flip-box-front"
                              data-bg-overlay="true"
                              data-text-color="light"
                              //    style="background-image: url('images/application.jpg'); background-color: #0a0a0a; min-height: 300px;"
                              style={{
                                backgroundImage:
                                  'url("images/application.jpg")',
                                backgroundColor: "#0a0a0a",
                                minHeight: "300px",
                              }}
                            >
                              <div className="inner">
                                <i
                                  className="icon-default-style icon-apps"
                                  data-color="extra-color-gradient-1"
                                  //  style="font-size: 55px!important; line-height: 55px!important;"
                                  style={{
                                    fontSize: "55px!important",
                                    lineHeight: "55px!important",
                                  }}
                                ></i>
                                <h3>Mobile Applications</h3>
                                <p>
                                  Intuitive cross-platform and native mobile
                                  application.
                                </p>
                              </div>
                            </div>
                            <div
                              className="flip-box-back"
                              data-bg-overlay="true"
                              data-text-color="light"
                              //  style="background-image: url('images/application.jpg'); background-color: #0a0a0a; background-image: url('images/application.jpg'); min-height: 300px;"
                              style={{
                                backgroundImage:
                                  'url("images/application.jpg")',
                                backgroundColor: "#0a0a0a",
                                minHeight: "300px",
                              }}
                            >
                              <div className="inner">
                                <p>
                                  {" "}
                                  We develop native apps for Android and iOS
                                  that provide full access to all hardware
                                  capabilities, interactive and smooth user
                                  experiences, offline support and a superior
                                  performance.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="carousel-item wpb_column has-animation"
                          data-animation="fade-in-from-bottom"
                          data-delay="400"
                        >
                          <div
                            className="nectar-flip-box"
                            data-min-height="300"
                            data-flip-direction="horizontal-to-left"
                            data-h_text_align="center"
                            data-v_text_align="center"
                          >
                            <div
                              className="flip-box-front"
                              data-bg-overlay="true"
                              data-text-color="light"
                              // style="background-image: url('images/software.jpg'); background-color: #0a0a0a; min-height: 300px;"
                              style={{
                                backgroundImage: 'url("images/software.jpg")',
                                backgroundColor: "#0a0a0a",
                                minHeight: "300px",
                              }}
                            >
                              <div className="inner">
                                <i
                                  className="icon-default-style icon-custom"
                                  data-color="extra-color-gradient-1"
                                  // style="font-size: 53px!important; line-height: 53px!important;"
                                  style={{
                                    fontSize: "53px!important",
                                    lineHeight: "53px!important",
                                  }}
                                ></i>
                                <h3>Custom Software</h3>
                                <p>
                                  {" "}
                                  End-to-end solutions for various verticals and
                                  business domains.{" "}
                                </p>
                              </div>
                            </div>
                            <div
                              className="flip-box-back"
                              data-bg-overlay="true"
                              data-text-color="light"
                              style={{
                                backgroundImage: "url('images/software.jpg')",
                                backgroundColor: "#0a0a0a",
                                minHeight: "300px",
                              }}
                            >
                              <div className="inner">
                                <p>
                                  {" "}
                                  Driven by dedicated product teams and smart
                                  design, with rapid agile development
                                  processes.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="carousel-item wpb_column has-animation"
                          data-animation="fade-in-from-bottom"
                          data-delay="0"
                        >
                          <div
                            className="nectar-flip-box"
                            data-min-height="300"
                            data-flip-direction="horizontal-to-left"
                            data-h_text_align="center"
                            data-v_text_align="center"
                          >
                            <div
                              className="flip-box-front"
                              data-bg-overlay="true"
                              data-text-color="light"
                              //style="background-image: url('images/ui-ux.jpg'); background-color: #0a0a0a; min-height: 300px;"
                              style={{
                                backgroundImage: 'url("images/ui-ux.jpg")',
                                backgroundColor: "#0a0a0a",
                                minHeight: "300px",
                              }}
                            >
                              <div className="inner">
                                <i
                                  className="icon-default-style icon-ui-ux"
                                  data-color="extra-color-gradient-1"
                                  //   style="font-size: 51px!important; line-height: 51px!important;"
                                  style={{
                                    fontSize: "51px!important",
                                    lineHeight: "51px!important",
                                  }}
                                ></i>
                                <h3>UI/UX</h3>
                                <p>
                                  Defining and planning user experience &
                                  interface for websites and applications.
                                </p>
                              </div>
                            </div>
                            <div
                              className="flip-box-back"
                              data-bg-overlay="true"
                              data-text-color="light"
                              // style="background-image: url('images/ui-ux.jpg'); background-color: #0a0a0a; background-image: url('images/ui-ux.jpg'); min-height: 300px;"
                              style={{
                                backgroundImage: 'url("images/ui-ux.jpg")',
                                backgroundColor: "#0a0a0a",
                              }}
                            >
                              <div className="inner">
                                <p>
                                  Professional visitors experience, unique and
                                  exciting like never before. easy and
                                  comfortable navigation flow.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="carousel-item wpb_column has-animation"
                          data-animation="fade-in-from-bottom"
                          data-delay="200"
                        >
                          <div
                            className="nectar-flip-box"
                            data-min-height="300"
                            data-flip-direction="horizontal-to-left"
                            data-h_text_align="center"
                            data-v_text_align="center"
                          >
                            <div
                              className="flip-box-front"
                              data-bg-overlay="true"
                              data-text-color="light"
                              // style="background-image: url('images/marketing.jpg'); background-color: #0a0a0a; min-height: 300px;"
                              style={{
                                backgroundImage: "url('images/marketing.jpg')",
                                backgroundColor: "#0a0a0a",
                                minHeight: "300px",
                              }}
                            >
                              <div className="inner">
                                <i
                                  className="icon-default-style icon-marketing"
                                  data-color="extra-color-gradient-1"
                                  style={{
                                    fontSize: "54px!important",
                                    lineHeight: "54px!important",
                                  }}
                                ></i>
                                <h3>Digital marketing</h3>
                                <p>
                                  Campaigns strategy for businesses, companies
                                  and startups.
                                </p>
                              </div>
                            </div>
                            <div
                              className="flip-box-back"
                              data-bg-overlay="true"
                              data-text-color="light"
                              // style="background-image: url('images/marketing.jpg'); background-color: #0a0a0a; background-image: url('images/marketing.jpg'); min-height: 300px;"
                              style={{
                                backgroundImage: "url('images/marketing.jpg')",
                                backgroundColor: "#0a0a0a",
                                minHeight: "300px",
                              }}
                            >
                              <div className="inner">
                                <p>
                                  Search engines, websites, social media, email
                                  & mobile apps marketing.{" "}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="carousel-item wpb_column has-animation"
                          data-animation="fade-in-from-bottom"
                          data-delay="400"
                        >
                          <div
                            className="nectar-flip-box"
                            data-min-height="300"
                            data-flip-direction="horizontal-to-left"
                            data-h_text_align="center"
                            data-v_text_align="center"
                          >
                            <div
                              className="flip-box-front"
                              data-bg-overlay="true"
                              data-text-color="light"
                              // style="background-image: url('images/design.jpg'); background-color: #0a0a0a; min-height: 300px;"
                              style={{
                                backgroundImage: "url('images/design.jpg')",
                                backgroundColor: "#0a0a0a",
                                minHeight: "300px",
                              }}
                            >
                              <div className="inner">
                                <i
                                  className="icon-default-style icon-design"
                                  data-color="extra-color-gradient-1"
                                  style={{
                                    fontSize: "48px!important",
                                    lineHeight: "48px!important",
                                  }}
                                ></i>
                                <h3>Design</h3>
                                <p>
                                  Graphics concept & design for websites,
                                  application & Social media.
                                </p>
                              </div>
                            </div>
                            <div
                              className="flip-box-back"
                              data-bg-overlay="true"
                              data-text-color="light"
                              //style="background-image: url('images/design.jpg'); background-color: #0a0a0a; background-image: url('images/design.jpg'); min-height: 300px;"
                              style={{
                                backgroundImage: "url('images/design.jpg')",
                                backgroundColor: "#0a0a0a",
                                minHeight: "300px",
                              }}
                            >
                              <div className="inner">
                                <p>
                                  Amazing design make your site more attractive
                                  to potential customers.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
