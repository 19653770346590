import React from "react";

function Advantages(props) {
  return (
    <div
      id="OurAdvantages"
      data-fullscreen-anchor-id="OurAdvantages-s"
      data-midnight="dark"
      data-bg-mobile-hidden=""
      className="wpb_row vc_row-fluid vc_row full-width-section  vc_row-o-equal-height vc_row-flex  vc_row-o-content-middle standard_section"
      style={{}}
    >
      <div className="full-page-inner-wrap-outer">
        <div
          className="full-page-inner-wrap"
          data-name="Our Advantages"
          data-content-pos="middle"
        >
          <div className="full-page-inner">
            <div className="row-bg-wrap">
              <div
                className="row-bg  using-bg-color  "
                //  style="background-color: #ecf7ff; "
                style={{ backgroundColor: "#ecf7ff" }}
                data-color_overlay=""
                data-color_overlay_2=""
                data-gradient_direction=""
                data-overlay_strength="0.3"
                data-enable_gradient="false"
              ></div>
            </div>
            <div className="container">
              <div className="col span_12 dark left f-mobile-margin">
                <div
                  className="vc_col-sm-5 wpb_column column_container vc_column_container col padding-2-percent"
                  data-bg-cover=""
                  data-padding-pos="all"
                  data-has-bg-color="false"
                  data-bg-color=""
                  data-bg-opacity="1"
                  data-hover-bg=""
                  data-hover-bg-opacity="1"
                  data-animation=""
                  data-delay="0"
                >
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      <div
                        className="nectar-icon-list"
                        data-icon-color="default"
                        data-icon-style="border"
                        data-icon-size="small"
                        data-animate="true"
                      >
                        <div className="nectar-icon-list-item">
                          <div
                            className="list-icon-holder"
                            data-icon_type="numerical"
                          >
                            <span>1</span>
                          </div>
                          <div className="content">
                            <h4>Agile, low-risk approach</h4>
                            We follow Agile Development methodology to rapidly
                            build complex software projects and efficiently
                            handle changing requirements.
                          </div>
                        </div>
                        <div className="nectar-icon-list-item">
                          <div
                            className="list-icon-holder"
                            data-icon_type="numerical"
                          >
                            <span>2</span>
                          </div>
                          <div className="content">
                            <h4>High-standards product quality</h4>
                            We use unified coding guidelines and frequent code
                            reviews to ensure the consistent code quality during
                            the entire development life cycle.
                          </div>
                        </div>
                        <div className="nectar-icon-list-item">
                          <div
                            className="list-icon-holder"
                            data-icon_type="numerical"
                          >
                            <span>3</span>
                          </div>
                          <div className="content">
                            <h4>Productivity</h4>
                            We use Agile development methodology, continuous
                            integration and DevOps tools to deliver fast product
                            development, automated testing and deployment.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="vc_col-sm-7 wpb_column column_container vc_column_container col no-extra-padding"
                  data-bg-cover=""
                  data-padding-pos="all"
                  data-has-bg-color="false"
                  data-bg-color=""
                  data-bg-opacity="1"
                  data-hover-bg=""
                  data-hover-bg-opacity="1"
                  data-animation=""
                  data-delay="0"
                >
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      <div
                        className="img-with-aniamtion-wrap "
                        data-max-width="175%"
                      >
                        <div className="inner">
                          <img
                            data-shadow="none"
                            data-shadow-direction="middle"
                            className="img-with-animation "
                            data-delay="0"
                            height="1504"
                            width="1606"
                            data-animation="fade-in-from-right"
                            src="images/mac-2.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advantages;
