import React from "react";

function Footer(props) {
  return (
    <div
      id="fws_58a74787dfb3f"
      data-midnight="light"
      data-bg-mobile-hidden=""
      className="wpb_row vc_row-fluid vc_row full-width-section  vc_row-o-equal-height vc_row-flex  vc_row-o-content-middle standard_section"
      style={{}}
    >
      <div className="full-page-inner-wrap-outer">
        <div
          className="full-page-inner-wrap"
          data-name=""
          data-content-pos="middle"
        >
          <div className="full-page-inner">
            <div className="row-bg-wrap">
              <div
                className="row-bg using-image"
                style={{
                  backgroundImage: "url(images/ui-ux.jpg)",
                  backgroundPosition: "center center",
                  backgroundRepeat: "no-repeat",
                }}
                data-color_overlay="#19693b"
                data-color_overlay_2="#48b74f"
                data-gradient_direction="left_to_right"
                data-overlay_strength="0.95"
                data-enable_gradient="true"
              ></div>
            </div>
            <div className="container">
              <div className="col span_12 light left">
                <div
                  className="vc_col-sm-6 wpb_column column_container vc_column_container col force-tablet-text-align-center force-phone-text-align-center padding-5-percent"
                  data-bg-cover=""
                  data-padding-pos="left-right"
                  data-has-bg-color="false"
                  data-bg-color=""
                  data-bg-opacity="1"
                  data-hover-bg=""
                  data-hover-bg-opacity="1"
                  data-animation=""
                  data-delay="0"
                >
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      <div className="divider-wrap">
                        <div
                          style={{ height: "50px" }}
                          className="divider"
                        ></div>
                      </div>
                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                          <h2>Want To Start a Project?</h2>
                          <p>It's simple</p>
                        </div>
                      </div>
                      <div className="divider-wrap">
                        <div
                          style={{ height: "50px" }}
                          className="divider"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="vc_col-sm-6 wpb_column column_container vc_column_container col force-tablet-text-align-center force-phone-text-align-center no-extra-padding"
                  data-bg-cover=""
                  data-padding-pos="all"
                  data-has-bg-color="false"
                  data-bg-color=""
                  data-bg-opacity="1"
                  data-hover-bg=""
                  data-hover-bg-opacity="1"
                  data-animation=""
                  data-delay="0"
                >
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      <div
                        id="fws_58a74787e04c7"
                        data-midnight=""
                        data-bg-mobile-hidden=""
                        className="wpb_row vc_row-fluid vc_row standard_section"
                        style={{
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <div className="row-bg-wrap">
                          <div className="row-bg" style={{}}></div>
                        </div>
                        <div className="col span_12  right">
                          <div
                            className="vc_col-sm-12 wpb_column column_container vc_column_container col no-extra-padding"
                            data-bg-cover=""
                            data-padding-pos="all"
                            data-has-bg-color="false"
                            data-bg-color=""
                            data-bg-opacity="1"
                            data-hover-bg=""
                            data-hover-bg-opacity="1"
                            data-animation=""
                            data-delay="0"
                          >
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                {" "}
                                <label
                                  htmlFor="Popup"
                                  className="contact-btn nectar-button jumbo see-through-extra-color-gradient-2"
                                  style={{}}
                                  data-color-override="false"
                                  data-hover-color-override="false"
                                  data-hover-text-color-override="#fff"
                                >
                                  <span className="start loading">
                                    Contact Us
                                  </span>
                                  <span className="hover">Contact Us</span>
                                </label>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Privacy Policy Button */}
      <div
        style={{
          backgroundColor: "#333",
        }}
      >
        <a
          href="/privacy-policy"
          style={{
            marginLeft: "10px !important",
          }}
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
}

export default Footer;
