import React from "react";

function About(props) {
  return (
    <div
      id="About"
      data-fullscreen-anchor-id="About-s"
      data-midnight="dark"
      data-bg-mobile-hidden=""
      className="wpb_row vc_row-fluid vc_row full-width-section  vc_row-o-equal-height vc_row-flex  vc_row-o-content-middle standard_section"
      style={{}}
    >
      <div className="full-page-inner-wrap-outer">
        <div
          className="full-page-inner-wrap"
          data-name="Who We Are"
          data-content-pos="middle"
        >
          <div className="full-page-inner">
            <div className="row-bg-wrap">
              <div
                className="row-bg  using-bg-color  "
                //style="background-color: #ffffff; "
                style={{ backgroundColor: "#ffffff" }}
                data-color_overlay=""
                data-color_overlay_2=""
                data-gradient_direction=""
                data-overlay_strength="0.3"
                data-enable_gradient="false"
              ></div>
            </div>
            <div className="container">
              <div className="col span_12 dark left">
                <div
                  className="vc_col-sm-7 wpb_column column_container vc_column_container col no-extra-padding"
                  data-bg-cover=""
                  data-padding-pos="all"
                  data-has-bg-color="false"
                  data-bg-color=""
                  data-bg-opacity="1"
                  data-hover-bg=""
                  data-hover-bg-opacity="1"
                  data-animation=""
                  data-delay="0"
                >
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      <div
                        className="img-with-aniamtion-wrap right"
                        data-max-width="165%"
                      >
                        <div className="inner">
                          <img
                            data-shadow="none"
                            data-shadow-direction="middle"
                            className="img-with-animation "
                            data-delay="0"
                            height="978"
                            width="1600"
                            data-animation="fade-in-from-left"
                            src="images/first-10.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="vc_col-sm-5 wpb_column column_container vc_column_container col padding-2-percent"
                  data-bg-cover=""
                  data-padding-pos="all"
                  data-has-bg-color="false"
                  data-bg-color=""
                  data-bg-opacity="1"
                  data-hover-bg=""
                  data-hover-bg-opacity="1"
                  data-animation=""
                  data-delay="0"
                >
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      <div
                        id="fws_58a74787d24a8"
                        data-midnight=""
                        data-bg-mobile-hidden=""
                        className="wpb_row vc_row-fluid vc_row standard_section"
                        //  style="padding-top: 0px; padding-bottom: 0px; "
                        style={{
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <div className="row-bg-wrap">
                          <div className="row-bg" style={{}}></div>
                        </div>
                        <div className="col span_12  left">
                          <div
                            className="vc_col-sm-12 wpb_column column_container vc_column_container col has-animation no-extra-padding"
                            data-bg-cover=""
                            data-padding-pos="all"
                            data-has-bg-color="false"
                            data-bg-color=""
                            data-bg-opacity="1"
                            data-hover-bg=""
                            data-hover-bg-opacity="1"
                            data-animation="fade-in-from-bottom"
                            data-delay="0"
                          >
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="nectar-gradient-text"
                                  data-direction="diagonal"
                                  data-color="extra-color-gradient-1"
                                  style={{}}
                                >
                                  <h1>Who We Are</h1>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="fws_58a74787d2a25"
                        data-midnight=""
                        data-bg-mobile-hidden=""
                        className="wpb_row vc_row-fluid vc_row standard_section"
                        style={{
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        <div className="row-bg-wrap">
                          <div className="row-bg" style={{}}></div>
                        </div>
                        <div className="col span_12  left">
                          <div
                            className="vc_col-sm-12 wpb_column column_container vc_column_container col has-animation no-extra-padding"
                            data-bg-cover=""
                            data-padding-pos="all"
                            data-has-bg-color="false"
                            data-bg-color=""
                            data-bg-opacity="1"
                            data-hover-bg=""
                            data-hover-bg-opacity="1"
                            data-animation="fade-in-from-bottom"
                            data-delay="150"
                          >
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div className="wpb_text_column wpb_content_element ">
                                  <div className="wpb_wrapper">
                                    <p>
                                      A small team of designers, developers &
                                      marketing experts, who help brands with
                                      big ideas. like craftsmen, we care about
                                      what we do, each pixel refined, every line
                                      of code considered. we provide a wide
                                      variety of web presence, products &
                                      solutions for brands, companies and
                                      dreamers. we see it as our responsibility
                                      to keep the web moving forwards. we set
                                      the right foundations so you’re ready for
                                      tomorrow. reusable code, responsive
                                      layouts, content first. we combine
                                      beautiful, modern designs with clean,
                                      functional code to produce stunning
                                      campaign. your business is important to
                                      us, which is why we strive to provide top
                                      notch service for our customers.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
