import React from "react";
import Svg1 from "../assets/aws-svgrepo-com.svg";
import Svg2 from "../assets/azure-svgrepo-com.svg";
import Svg3 from "../assets/docker-svgrepo-com.svg";
import Svg4 from "../assets/c-sharp-svgrepo-com.svg";
import Svg5 from "../assets/firebase-svgrepo-com.svg";
import Svg6 from "../assets/github-svgrepo-com.svg";
import Svg7 from "../assets/nestjs-svgrepo-com.svg";
import Svg8 from "../assets/nodejs-svgrepo-com.svg";
import Svg9 from "../assets/react-svgrepo-com.svg";
import Svg10 from "../assets/vercel-svgrepo-com.svg";
const TechIcons = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
        padding: "10px",
        margin: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <img
        src={Svg1}
        alt="AWS Icon"
        style={{ width: "50px", height: "50px" }}
      />
      <img
        src={Svg2}
        alt="Azure Icon"
        style={{ width: "50px", height: "50px" }}
      />
      <img
        src={Svg3}
        alt="Docker Icon"
        style={{ width: "50px", height: "50px" }}
      />
      <img
        src={Svg4}
        alt="C Sharp Icon"
        style={{ width: "50px", height: "50px" }}
      />
      <img
        src={Svg5}
        alt="Firebase Icon"
        style={{ width: "50px", height: "50px" }}
      />
      <img
        src={Svg6}
        alt="GitHub Icon"
        style={{ width: "50px", height: "50px" }}
      />
      <img
        src={Svg7}
        alt="NestJS Icon"
        style={{ width: "50px", height: "50px" }}
      />
      <img
        src={Svg8}
        alt="Node.js Icon"
        style={{ width: "50px", height: "50px" }}
      />
      <img
        src={Svg9}
        alt="React Icon"
        style={{ width: "50px", height: "50px" }}
      />
      <img
        src={Svg10}
        alt="Vercel Icon"
        style={{ width: "50px", height: "50px" }}
      />
    </div>
  );
};

export default TechIcons;
