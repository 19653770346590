import React from "react";
import styles from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.header}>Privacy Policy for CodeGator</h1>
      <p className={styles.text}>
        At CodeGator, accessible from www.codegator.co, one of our main
        priorities is the privacy of our visitors. This Privacy Policy document
        contains types of information that is collected and recorded by
        CodeGator and how we use it.
      </p>

      <h2 className={styles.sectionTitle}>Information We Collect</h2>
      <p className={styles.text}>
        When you use our services, we may ask for your personal information,
        including items such as name, company name, address, email address, and
        telephone number.
      </p>

      <h2 className={styles.sectionTitle}>How We Use Your Information</h2>
      <p className={styles.text}>
        We use the information we collect in various ways, including to:
      </p>
      <ul className={styles.list}>
        <li>Provide, operate, and maintain our website</li>
        <li>Improve, personalize, and expand our website</li>
        <li>Understand and analyze how you use our website</li>
        <li>Develop new products, services, features, and functionality</li>
      </ul>

      <h2 className={styles.sectionTitle}>Log Files</h2>
      <p className={styles.text}>
        CodeGator follows a standard procedure of using log files. These files
        log visitors when they visit websites. The information collected by log
        files include internet protocol (IP) addresses, browser type, Internet
        Service Provider (ISP), date and time stamp, referring/exit pages, and
        possibly the number of clicks.
      </p>

      <h2 className={styles.sectionTitle}>Cookies and Web Beacons</h2>
      <p className={styles.text}>
        Like any other website, CodeGator uses 'cookies'. These cookies are used
        to store information including visitors' preferences, and the pages on
        the website that the visitor accessed or visited.
      </p>

      <h2 className={styles.sectionTitle}>Contact Us</h2>
      <p className={styles.text}>
        If you have any questions about this Privacy Policy, please contact us.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
