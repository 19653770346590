import React from "react";
import TechIcons from "./Tech";

function Intro(props) {
  return (
    <div
      id="Intro"
      data-fullscreen-anchor-id="Intro-s"
      data-midnight="light"
      data-bg-mobile-hidden=""
      className="wpb_row vc_row-fluid vc_row full-width-section standard_section"
    >
      <div className="full-page-inner-wrap-outer">
        <div
          className="full-page-inner-wrap"
          data-name="Intro"
          data-content-pos="middle"
        >
          <div className="full-page-inner">
            <div className="row-bg-wrap">
              <div
                className="row-bg using-image"
                style={{
                  backgroundImage: 'url("images/smile-1.jpg")',
                  backgroundPosition: "center top",
                  backgroundRepeat: "no-repeat",
                }}
                data-color_overlay="#0a0a0a"
                data-color_overlay_2=""
                data-gradient_direction=""
                data-overlay_strength="0.5"
                data-enable_gradient="false"
              ></div>
            </div>
            <div className="container">
              <div className="col span_12 light left">
                <div
                  className="vc_col-sm-12 wpb_column column_container vc_column_container col centered-text padding-6-percent"
                  data-bg-cover=""
                  data-padding-pos="all"
                  data-has-bg-color="false"
                  data-bg-color=""
                  data-bg-opacity="1"
                  data-hover-bg=""
                  data-hover-bg-opacity="1"
                  data-animation=""
                  data-delay="0"
                >
                  <div className="vc_column-inner">
                    <div className="wpb_wrapper">
                      <div
                        id="fws_58a74787cfbde"
                        data-midnight=""
                        data-bg-mobile-hidden=""
                        className="wpb_row vc_row-fluid vc_row standard_section"
                        style={{
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                        //style="padding-top: 0px; padding-bottom: 0px; "
                      >
                        <div className="row-bg-wrap">
                          <div className="row-bg"></div>
                        </div>
                        <div className="col span_12 left">
                          <div
                            className="vc_col-sm-12 wpb_column column_container vc_column_container col has-animation no-extra-padding"
                            data-bg-cover=""
                            data-padding-pos="all"
                            data-has-bg-color="false"
                            data-bg-color=""
                            data-bg-opacity="1"
                            data-hover-bg=""
                            data-hover-bg-opacity="1"
                            data-animation="fade-in-from-bottom"
                            data-delay="0"
                          >
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div
                                  className="wpb_text_column wpb_content_element "
                                  //style=" max-width: 700px; display: inline-block;"
                                  style={{
                                    maxWidth: "700px",
                                    display: "inline-block",
                                  }}
                                >
                                  <div className="wpb_wrapper">
                                    <h1>Making The Complex Simple</h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="fws_58a74787d073f"
                        data-midnight=""
                        data-bg-mobile-hidden=""
                        className="wpb_row vc_row-fluid vc_row standard_section"
                        style={{
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                        // style="padding-top: 0px; padding-bottom: 0px; "
                      >
                        <div className="row-bg-wrap">
                          <div className="row-bg" style={{}}></div>
                        </div>
                        <div className="col span_12  left">
                          <div
                            className="vc_col-sm-12 wpb_column column_container vc_column_container col has-animation no-extra-padding"
                            data-bg-cover=""
                            data-padding-pos="all"
                            data-has-bg-color="false"
                            data-bg-color=""
                            data-bg-opacity="1"
                            data-hover-bg=""
                            data-hover-bg-opacity="1"
                            data-animation="fade-in-from-bottom"
                            data-delay="100"
                          >
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div className="wpb_text_column wpb_content_element  vc_custom_1469884637425">
                                  <div className="wpb_wrapper">
                                    <p>
                                      No matter what medium or platform, we
                                      bring clarity and ease.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id="fws_58a74787d0d1c"
                        data-midnight=""
                        data-bg-mobile-hidden=""
                        className="wpb_row vc_row-fluid vc_row standard_section"
                        style={{
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                        // style="padding-top: 0px; padding-bottom: 0px; "
                      >
                        <div className="row-bg-wrap">
                          <div className="row-bg" style={{}}></div>
                        </div>
                        <div className="col span_12  left">
                          <div
                            className="vc_col-sm-12 wpb_column column_container vc_column_container col has-animation padding-4-percent"
                            data-bg-cover=""
                            data-padding-pos="all"
                            data-has-bg-color="false"
                            data-bg-color=""
                            data-bg-opacity="1"
                            data-hover-bg=""
                            data-hover-bg-opacity="1"
                            data-animation="fade-in-from-bottom"
                            data-delay="200"
                          >
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                {" "}
                                <a
                                  className="scroll-down nectar-button jumbo see-through-extra-color-gradient-1"
                                  style={{}}
                                  href="#About"
                                  data-index="1"
                                  data-color-override="false"
                                  data-hover-color-override="false"
                                  data-hover-text-color-override="#fff"
                                >
                                  <span className="start loading">
                                    Show Me More
                                  </span>
                                  <span className="hover">Show Me More</span>
                                </a>{" "}
                                {/* Additional "Contact Now" button */}
                                <button
                                  className="contact-now nectar-button jumbo see-through-extra-color-gradient-1"
                                  data-index="1"
                                  data-color-override="false"
                                  data-hover-color-override="false"
                                  data-hover-text-color-override="#fff"
                                  style={{
                                    marginLeft: "10px",
                                    cursor: "pointer",
                                  }} // Add some spacing between buttons and cursor style
                                  onClick={() =>
                                    (document.getElementById(
                                      "Popup"
                                    ).checked = true)
                                  } // Trigger the modal to open
                                >
                                  Contact Now
                                </button>
                              </div>
                              <TechIcons />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intro;
