import React from "react";

function Header(props) {
  return (
    <div
      id="header-outer"
      data-has-menu="true"
      data-transparent-header="true"
      data-remove-border="true"
      className="transparent"
      data-mobile-fixed="1"
      data-ptnm="false"
      data-lhe="default"
      data-user-set-bg="#ffffff"
      data-format="default"
      data-permanent-transparent="false"
      data-cart="false"
      data-transparency-option="1"
      data-shrink-num="6"
      data-full-width="true"
      data-using-secondary="0"
      data-using-logo="1"
      data-logo-height=""
      data-m-logo-height="24"
      data-padding="28"
      data-header-resize="1"
    >
      <header id="top">
        <div className="container">
          <div className="row">
            <div className="col span_3">
              <a id="logo" href="/">
                <img
                  className="stnd default-logo"
                  alt="Salient Service"
                  src="images/logo2.png"
                />
                <img
                  className="retina-logo "
                  alt="Salient Service"
                  src="images/logo2.png"
                />
                <img
                  className="starting-logo default-logo"
                  alt="Salient Service"
                  src="images/logo2.png"
                />
                <img
                  className="retina-logo starting-logo"
                  alt="Salient Service"
                  src="images/logo2.png"
                />
                <img
                  className="starting-logo dark-version default-logo"
                  alt="Salient Service"
                  src="images/logo2.png"
                />
                <img
                  className="retina-logo starting-logo dark-version "
                  alt="Salient Service"
                  src="images/logo2.png"
                />
              </a>
            </div>
            <div className="col span_9 col_last">
              <div
                className="slide-out-widget-area-toggle mobile-icon slide-out-from-right-hover"
                data-icon-animation="simple-transform"
              >
                <div>
                  {" "}
                  <a href="#sidewidgetarea" className="closed">
                    {" "}
                    <span>
                      {" "}
                      <i className="lines-button x2">
                        {" "}
                        <i className="lines"></i>{" "}
                      </i>{" "}
                    </span>{" "}
                  </a>{" "}
                </div>
              </div>
              <nav>
                <ul className="buttons" data-user-set-ocm="off">
                  <li id="search-btn">
                    <div>
                      <a href="#searchbox">
                        {" "}
                        <span
                          className="icon-salient-search"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </div>
                  </li>
                  <li
                    className="slide-out-widget-area-toggle"
                    data-icon-animation="simple-transform"
                  >
                    <div>
                      {" "}
                      <a href="#sidewidgetarea" className="closed">
                        {" "}
                        <span>
                          {" "}
                          <i className="lines-button x2">
                            <i className="lines"></i>{" "}
                          </i>{" "}
                        </span>{" "}
                      </a>{" "}
                    </div>
                  </li>
                </ul>
                <ul className="sf-menu">
                  <li
                    id="menu-item-100"
                    data-index="1"
                    className="menu-item menu-item-type-post_type menu-item-object-page page_item page-item-2 menu-item-107"
                  >
                    <a href="#">About</a>
                  </li>
                  <li
                    id="menu-item-101"
                    data-index="2"
                    className="s-hide menu-item menu-item-type-post_type menu-item-object-page page_item page-item-2 menu-item-107"
                  >
                    <a href="#">Advantages</a>
                  </li>
                  <li
                    id="menu-item-102"
                    data-index="3"
                    className="menu-item menu-item-type-post_type menu-item-object-page menu-item-106"
                  >
                    <a href="#">Services</a>
                  </li>

                  <li
                    id="menu-item-104"
                    data-index="4"
                    className="s-hide menu-item menu-item-type-custom menu-item-object-custom menu-item-108"
                  >
                    <a href="#">Technologies</a>
                  </li>
                  {/* <li
                    id="menu-item-105"
                    data-index="6"
                    className="s-hide menu-item menu-item-type-custom menu-item-object-custom menu-item-108"
                  >
                    <a href="#">Agenda</a>
                  </li> */}
                  <li className="label">
                    <a href="#">
                      <label htmlFor="Popup">Contact</label>
                    </a>
                  </li>
                  <li id="social-in-menu" className="button_social_group">
                    <a href="#">
                      <i className="fa fa-twitter"></i>{" "}
                    </a>
                    <a href="#">
                      <i className="fa fa-facebook"></i>
                    </a>
                    <a href="#">
                      <i className="fa fa-youtube-play"></i>{" "}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <div className="ns-loading-cover"></div>
    </div>
  );
}

export default Header;
